import ComboBoxInputListModalComponent from '@wix/thunderbolt-elements/src/components/ComboBoxInputListModal/viewer/ComboBoxInputListModal';


const ComboBoxInputListModal = {
  component: ComboBoxInputListModalComponent
};


export const components = {
  ['ComboBoxInputListModal']: ComboBoxInputListModal
};

